import React from 'react';

const About = () => {
  return (
    <div className="bg-white mb-8">
      <h2 className="text-2xl font-semibold pt-8 mb-6 text-center">About LIDA Engineering Limited</h2>
      <div className="max-w-[1000px] m-auto p-6">
        <p className="mb-4">
          More info
        </p>
        <p className="mb-4">
          Coming Soon
        </p>
      </div>
    </div>
  );
};

export default About;
